import React from 'react'
import styled from 'styled-components'

// Components
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'

const FormWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
`

const Content = styled(ParseContent)`
  border-left: 2px solid ${(props) => props.theme.color.face.secondary};
  padding-left: 2rem;

  a:hover {
    text-decoration: underline;
  }
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.font.size['21']};
  font-weight: ${(props) => props.theme.font.weight.s};
`

const FormTitle = styled(ParseContent)`
  font-size: ${(props) => props.theme.font.size[16]};
`

interface FormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const Form: React.FC<FormProps> = ({ fields }) => (
  <section className="py-5 my-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-12">
          <div className="row">
            {fields?.info?.map((info, index: number) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="col-md-6 col-lg-12 mb-5"
              >
                <div className="row">
                  <div className="col-xl-5 col-lg-6 col-12">
                    <Title>{info?.title}</Title>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-12">
                    <Content content={info?.description} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <FormWrapper className="col-lg-5 col-12 px-lg-5 px-4 pt-lg-5 pt-4 pb-lg-2 pb-2">
          <FormTitle content={fields.description} />
          <GravityForm id={Number(fields.formId)} />
        </FormWrapper>
      </div>
    </div>
  </section>
)

export default Form
